<template>
  <div>
    <h1 class="text-2xl mb-6">Campaign Tracker</h1>
    <v-card class="pt-7 pb-3 mb-3">
      <v-row class="ml-6 mr-6">
        <v-col cols="12" md="6">
          <v-select
            v-model="partnerSelected"
            :items="partnerOptions"
            label="Partner"
            multiple
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <date-filter @valueChanged="updateRangeDate"></date-filter>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pt-7 pb-3 mb-3">
      <v-row class="ml-6 mr-6" justify="center">
        <pie-chart
          title="Clicks"
          :labels="labelsPartner"
          :series="seriesClicks"
        ></pie-chart>
        <pie-chart
          title="Impressions"
          :labels="labelsPartner"
          :series="seriesImpressions"
        >
        </pie-chart>
        <pie-chart
          title="Spent"
          :labels="labelsPartner"
          :series="seriesSpent"
        ></pie-chart>
      </v-row>
    </v-card>
    <v-card class="pt-7 pb-3 mb-3">
      <vue-apex-charts
        type="bar"
        :options="chartOptions"
        :series="series"
      ></vue-apex-charts>
    </v-card>
    <v-card class="pt-7 pb-3 mb-3">
      <v-data-table :headers="tableHeaders" :items="items" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Selected Campaigns</v-toolbar-title>
          </v-toolbar>
        </template>
        <template v-slot:item.created="{ item }">
          {{ formatDate(item.created) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "@axios";
import VueApexCharts from "vue-apexcharts";
import DateFilter from "@/components/common/filters/DateFilter";
import PieChart from "@/components/common/charts/PieChart.vue";
import { paramsSerializer } from "@/utils/paramsSerializer";
import moment from "moment";
export default {
  name: "campain-tracker",
  components: {
    VueApexCharts,
    DateFilter,
    PieChart,
  },
  data() {
    return {
      labelsPartner: [],
      seriesClicks: [],
      seriesImpressions: [],
      seriesSpent: [],
      items: [],
      partnerSelected: [],
      partnerOptions: ["Taboola", "Outbrain"],
      startDate: "",
      endDate: "",
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          stacked: false, // To stack the columns
          height: 50,
        },
        xaxis: {
          categories: [],
        },
        title: {
          text: "Campaign Data",
        },
        legend: {
          position: "top",
        },
      },
      tableHeaders: [
        { text: "Advertiser Identifier", value: "advertiser_identifier" },
        { text: "Advertiser Name", value: "advertiser_name" },
        { text: "Campaign Identifier", value: "campaign_identifier" },
        { text: "Campaign Name", value: "campaign_name" },
        { text: "Partner Name", value: "partner_name" },
        { text: "Created", value: "created" },
        { text: "Impressions", value: "impressions" },
        { text: "Clicks", value: "clicks" },
        { text: "Spend", value: "spent" },
        { text: "Cpc", value: "cpc" },
      ],
    };
  },
  created() {
    // this.getSummaryCampaigns();
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    updateRangeDate(value) {
      this.startDate = value[0];
      this.endDate = value[1];
    },
    async getSummaryCampaigns() {
      this.labelsPartner = [];
      this.seriesClicks = [];
      this.seriesImpressions = [];
      this.seriesSpent = [];
      const params = {
        startDate: this.startDate,
        endDate: this.endDate,
        partners: this.partnerSelected,
      };
      const items = await axios.get("/arbitrage_campaign/rmrCampaignsSummary", {
        params,
        paramsSerializer: paramsSerializer,
      });
      // console.log(items.data.total_by_partner);
      for (let partner in items.data.total_by_partner) {
        // console.log(partner);
        this.labelsPartner.push(partner);
        this.seriesClicks.push(items.data.total_by_partner[partner].clicks);
        this.seriesImpressions.push(
          items.data.total_by_partner[partner].impressions
        );
        this.seriesSpent.push(items.data.total_by_partner[partner].spent);
      }
      this.items = items.data.campaigns;
      // console.log(this.items);
      this.updateChartData();
    },
    updateChartData() {
      // Generate the full range of dates
      const startDate = moment(this.startDate);
      const endDate = moment(this.endDate);
      const dates = [];
      let currentDate = startDate.clone();

      while (currentDate <= endDate) {
        dates.push(currentDate.format("YYYY-MM-DD"));
        currentDate = currentDate.add(1, "day");
      }

      // Initialize campaign counts for each partner for every date
      const groupedData = dates.reduce((acc, date) => {
        acc[date] = {};
        this.partnerOptions.forEach((partner) => {
          acc[date][partner] = 0;
        });
        return acc;
      }, {});

      // Populate the actual campaign counts
      this.items.forEach((item) => {
        const date = moment(item.created).format("YYYY-MM-DD");
        if (groupedData[date]) {
          groupedData[date][item.partner_name] += 1;
        }
      });

      // Extract categories (dates) and series data
      const categories = Object.keys(groupedData).sort(
        (a, b) => new Date(a) - new Date(b)
      );
      const partners = this.partnerOptions;

      const series = partners.map((partner) => {
        return {
          name: partner,
          data: categories.map((date) => {
            return {
              x: date,
              y: groupedData[date][partner] || 0,
            };
          }),
        };
      });

      this.chartOptions.xaxis.categories = categories;
      this.series = series;
    },
  },
  watch: {
    partnerSelected() {
      // console.log(this.partnerSelected);
      this.getSummaryCampaigns();
    },
    endDate() {
      this.getSummaryCampaigns();
    },
  },
};
</script>
<style></style>
